@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Kosugi+Maru&display=swap");
@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@700&display=swap");
/* common 
-------------------------------*/
body {
  font-family: "M PLUS Rounded 1c", sans-serif;
  color: #0e0e0e;
  font-size: 16px;
}

header {
  position: sticky;
  top: 0;
  min-height: 80px;
  -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  color: #707070;
  z-index: 100;
  /*position: relative;*/
  background-color: #fff;
}
header #header-in {
  max-width: 1120px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
header #h-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
header #h-left h1 {
  margin-right: 23px;
}
header #h-left p {
  font-size: 12px;
  line-height: 20px;
}
header #h-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 80px;
}
header #h-blog {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 9.5px;
  margin-bottom: 6.5px;
  padding-left: 12.5px;
  padding-right: 12.5px;
  border: 1px solid #ababab;
  border-top: none;
  border-bottom: none;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  opacity: 1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
header #h-blog:hover {
  opacity: 0.6;
}
header #h-tel {
  padding-left: 22.5px;
  margin-right: 13px;
}
header #h-tel p {
  margin-top: 24px;
  padding-left: 32px;
  font-size: 25px;
  background-image: url(../img/icon_tel.svg);
  background-repeat: no-repeat;
  line-height: 25px;
}
header #h-tel span {
  display: block;
  text-align: right;
  font-size: 12px;
  line-height: 20px;
}
header #h-mail {
  background-color: #0e0e0e;
  color: #ffffff;
  opacity: 1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
header #h-mail a {
  display: block;
  min-height: 80px;
  padding-top: 41px;
  padding-right: 20px;
  padding-left: 20px;
  background-image: url(../img/Icon_email.svg);
  background-repeat: no-repeat;
  background-position: center 15.5px;
  font-size: 22px;
}
header #h-mail:hover {
  opacity: 0.6;
}

#mv {
  margin-bottom: 35px;
  text-align: center;
}
#mv img {
  z-index: 1;
  margin-top: -61px;
  width: 100%;
  max-width: 1120px;
}

.top-inner {
  max-width: 1120px;
  margin: 0 auto;
}

.top-h2ttl {
  position: relative;
  padding-top: 127px;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  background-position: center 0;
  background-repeat: no-repeat;
}
.top-h2ttl span {
  color: #e95959;
}

.top-h2ttl__ub {
  padding-bottom: 22.5px;
}

.top-h2ttl__ub::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: calc(50% - 22px);
  display: inline-block;
  background-image: url("../img/ttl_ul.svg");
  background-repeat: no-repeat;
  width: 44px;
  height: 5px;
}

.top-lead {
  margin-top: 19px;
  text-align: center;
  line-height: 30px;
}

.flex-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#feature {
  padding-top: 80px;
  padding-bottom: 116.38px;
  background-color: #f0efef;
}
#feature .top-h2ttl {
  background-image: url(../img/icon_light.svg);
}
#feature .top-lead {
  margin-bottom: 14px;
}
#feature .flex-wrapper {
  max-width: 1120px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
#feature .flex-wrapper dl {
  width: 47.3214285714%;
  max-width: 530px;
  margin-top: 30px;
  background-color: #fff;
  border-radius: 30px;
  text-align: center;
  background-repeat: no-repeat;
}
#feature .flex-wrapper dl:nth-child(1) {
  background-position: 89.4339622642% 86.0198675497%;
}
#feature .flex-wrapper dl:nth-child(2) {
  background-position: 87.6566037736% 85.9370860927%;
}
#feature .flex-wrapper dl:nth-child(3) {
  background-position: 87.020754717% 85.9701986755%;
}
#feature .flex-wrapper dl:nth-child(4) {
  background-position: 88.3056603774% 92.0794701987%;
}
#feature .flex-wrapper dl:nth-child(5) {
  background-position: 91.6716981132% 83.9867549669%;
}
#feature .flex-wrapper dl:nth-child(6) {
  background-position: 93.5849056604% 81.7880794702%;
}
#feature .flex-wrapper dt {
  padding-top: 30px;
  padding-bottom: 17.5px;
  border-bottom: 1px dashed #707070;
  color: #e95959;
  font-size: 24px;
  line-height: 40px;
}
#feature .flex-wrapper dd {
  width: 49.4339622642%;
  padding-top: 29.5px;
  padding-left: 40px;
  padding-bottom: 44px;
  font-size: 14px;
  line-height: 25px;
  text-align: left;
}
#feature .flex-wrapper dl:nth-child(1) {
  background-image: url(../img/icon_feat01.svg);
}
#feature .flex-wrapper dl:nth-child(2) {
  background-image: url(../img/icon_feat02.svg);
}
#feature .flex-wrapper dl:nth-child(3) {
  background-image: url(../img/icon_feat03.svg);
}
#feature .flex-wrapper dl:nth-child(4) {
  background-image: url(../img/icon_feat04.svg);
}
#feature .flex-wrapper dl:nth-child(5) {
  background-image: url(../img/icon_feat05.svg);
}
#feature .flex-wrapper dl:nth-child(6) {
  background-image: url(../img/icon_feat06.svg);
}

#case {
  padding-top: 100px;
}
#case .top-h2ttl {
  background-image: url(../img/icon_ithitsuji.svg);
}
#case #case-list {
  margin-top: 48px;
  margin-bottom: 81px;
  border-top: 1px dashed #707070;
}
#case #case-list > li {
  border-bottom: 1px dashed #707070;
}
#case #case-list dl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 1120px;
  margin: 0 auto;
  padding: 50px 0 50px 100px;
}
#case #case-list dt {
  width: 46%;
  font-weight: normal;
  font-size: 22px;
}
#case #case-list dd {
  width: 54%;
  font-size: 16px;
  line-height: 25px;
}
#case #case-list dd span {
  color: #edb5b2;
}
#case #case-list dd li {
  text-indent: -1em;
}
#case #top-logoarea {
  max-width: 1120px;
  margin: 0 auto;
}
#case #top-logoarea ul {
  padding-left: 130px;
  padding-right: 130px;
  padding-bottom: 55px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
#case #top-logoarea li {
  width: 25%;
  margin-bottom: 56px;
  text-align: center;
  position: relative;
}
#case #top-logoarea li .logo1:hover + .balloon1 {
  display: inline-block;
}
#case #top-logoarea li .logo2:hover + .balloon2 {
  display: inline-block;
}
#case #top-logoarea li .logo3:hover + .balloon3 {
  display: inline-block;
}
#case #top-logoarea li .logo4:hover + .balloon4 {
  display: inline-block;
}
#case #top-logoarea li .logo5:hover + .balloon5 {
  display: inline-block;
}
#case #top-logoarea li .logo6:hover + .balloon6 {
  display: inline-block;
}
#case #top-logoarea li .logo7:hover + .balloon7 {
  display: inline-block;
}
#case #top-logoarea li .logo8:hover + .balloon8 {
  display: inline-block;
}
#case #top-logoarea .balloon {
  display: none;
  position: absolute;
  bottom: 100px;
  left: 0;
  /*display: inline-block;*/
  margin: 1.5em 0;
  min-width: 120px;
  max-width: 100%;
  color: #555;
  font-size: 16px;
  background: #f8f6e1;
  border-radius: 10px;
  -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
#case #top-logoarea .balloon dt {
  padding: 15px 15px 12px 15px;
  border-bottom: 1px solid #d0d0d0;
  font-size: 16px;
  font-weight: normal;
}
#case #top-logoarea .balloon dd {
  padding: 15px;
  font-size: 14px;
  line-height: 23px;
}
#case #top-logoarea .balloon:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -15px;
  border: 15px solid transparent;
  border-top: 15px solid #f8f6e1;
}
#case #top-logoarea .balloon p {
  margin: 0;
  padding: 0;
}
#case h3 {
  max-width: 51.5178571429%;
  margin: 0 auto 70px auto;
  padding: 14px 50px;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
  font-size: 28px;
  text-align: center;
}
#case h3 span {
  color: #e95959;
}

#plan {
  padding-top: 100px;
  padding-bottom: 113px;
  background-color: #f0efef;
}
#plan .top-h2ttl {
  background-image: url(../img/icon_ithitsuji.svg);
}
#plan #top-cpn {
  margin-top: 42px;
  margin-bottom: 48px;
}
#plan img {
  width: 100%;
  height: auto;
}

#flow {
  padding-top: 100px;
  padding-bottom: 100px;
}
#flow .top-h2ttl {
  background-image: url(../img/icon_ithitsuji.svg);
}
#flow #step {
  margin-top: 86px;
}
#flow img {
  width: 100%;
  height: auto;
}
#flow sub {
  display: block;
  margin-top: 40px;
  text-align: center;
}

#contact {
  padding-bottom: 88px;
  background-color: #f0efef;
}
#contact h2 {
  padding-top: 29px;
  padding-bottom: 60px;
  background-color: #e95959;
  font-size: 28px;
  font-weight: bold;
  line-height: 45px;
  color: #fff;
  background-image: url(../img/icon-arrow.svg);
  background-position: center bottom 28.5px;
  background-repeat: no-repeat;
  text-align: center;
}
#contact .error {
  color: #e95959;
  margin-top: 10px;
  -webkit-animation: blinking 0.3s ease-in infinite;
          animation: blinking 0.3s ease-in infinite;
  -webkit-animation-iteration-count: 2;
          animation-iteration-count: 2;
  font-size: 13px;
  padding-left: 10px;
}
@-webkit-keyframes blinking {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blinking {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#contact .note {
  margin-top: 24px;
  margin-bottom: 43px;
  text-align: center;
}
#contact dl {
  width: 100%;
  max-width: 585px;
  margin: 0 auto;
}
#contact dt {
  margin-bottom: 8px;
  font-weight: normal;
  font-size: 16px;
}
#contact dd {
  margin-bottom: 27px;
}
#contact input[type=text],
#contact input[type=tel],
#contact input[type=email],
#contact textarea {
  width: 100%;
  padding: 17px 19px;
  background-color: #fff;
  border: 1px solid #bababa;
  border-radius: 10px;
}
#contact #cntct-btn {
  margin-top: 14px;
  text-align: center;
}
#contact #cntct-btn input {
  margin: 0 auto;
  padding: 12px;
  width: 219px;
  border-radius: 40px;
  background-color: #0e0e0e;
  color: #fff;
  font-size: 30px;
  text-align: center;
  opacity: 1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
#contact #cntct-btn input:hover {
  opacity: 0.6;
}
#contact .modal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 111;
}
#contact .modal .modal-content {
  position: absolute;
  padding: 45px 50px;
  text-align: center;
  top: 50%;
  left: 50%;
  background-color: #f0efef;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 90%;
  max-width: 500px;
  height: auto;
  border-radius: 20px;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 768px) {
  #contact .modal .modal-content {
    padding: 30px;
  }
}
#contact .modal .modal-content .message {
  line-height: 1.6;
}
#contact .modal .modal-content .message .fas {
  font-size: 60px;
  margin-bottom: 25px;
}
@media screen and (max-width: 768px) {
  #contact .modal .modal-content .message .fas {
    font-size: 50px;
  }
}
#contact .modal .modal-content .message.complete .fas {
  color: #71cfae;
}
#contact .modal .modal-content .message.failed .fas {
  color: #E95959;
}
#contact .modal .modal-content .message .txt {
  font-size: 25px;
}
@media screen and (max-width: 768px) {
  #contact .modal .modal-content .message .txt {
    font-size: 20px;
  }
}
#contact .modal .modal-content .message .supplemental {
  padding-top: 15px;
  display: inline-block;
  text-align: left;
  font-size: 15px;
}
@media screen and (max-width: 768px) {
  #contact .modal .modal-content .message .supplemental {
    font-size: 13px;
  }
}
#contact .modal .modal-content button {
  background-color: #fff;
  border-radius: 99999px;
  padding: 10px 35px;
  margin-top: 15px;
  border: 1px solid #707070;
}
@media screen and (max-width: 768px) {
  #contact .modal .modal-content button {
    margin-top: 20px;
  }
}

#footer-in {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 30px;
  padding-bottom: 15px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 1120px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 24px;
}
#footer-in .logo {
  padding-right: 40px;
}
#footer-in #footer-about {
  padding-left: 35px;
  border-left: 1px solid #d0d0d0;
}
#footer-in #footer-about span {
  font-weight: bold;
}

#footer-copy {
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #d0d0d0;
  text-align: center;
  color: #707070;
  font-size: 12px;
}

/*--------------------------------
  scroll animation fadein
--------------------------------*/
.js-scroll-fadein {
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
  visibility: hidden;
  opacity: 0;
}
.js-scroll-fadein.is-fadein {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------
    arrow
  --------------------------------*/
.arrow {
  position: fixed;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border-radius: 80px;
}
.arrow:hover {
  opacity: 0.8;
  bottom: 15px;
  -webkit-box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.5);
          box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.5);
}

.low-footer {
  border-top: 1px solid #d0d0d0;
}

/* archive
--------------------------*/
#wrapper {
  max-width: 1120px;
  padding-top: 60px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

#main {
  width: 70%;
}

#sidebar {
  width: 25%;
}

#arc-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-bottom: 80px;
}
#arc-list article {
  width: 45%;
  padding-top: 40px;
  -webkit-transition: 0.6;
  transition: 0.6;
}
#arc-list article .img {
  width: 100%;
  height: 40%;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
#arc-list article .img:hover {
  opacity: 0.6;
}
#arc-list article img {
  width: 100%;
  height: auto;
}
#arc-list h3 {
  font-size: 18px;
  line-height: 1.6;
  font-weight: 700;
  min-height: 85px;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
#arc-list h3 a {
  display: block;
  padding: 20px 0 5px;
}
#arc-list .txt-body {
  font-size: 14px;
  line-height: 22px;
}
#arc-list .date {
  margin-top: 1em;
  font-size: 14px;
  color: #707070;
}

.low-h2ttl {
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
  color: #e95959; /*文字色*/
  /*線の種類（点線）2px 線色*/
  border-bottom: dashed 2px #e95959;
}

/* Single
-----------------------------------*/
#main.single img {
  max-width: 100%;
  height: auto;
}
#main.single .post-ttl {
  display: block;
  padding: 0px 0px 20px;
}
#main.single .single-ttl {
  margin-bottom: 10px;
  font-size: 30px;
  line-height: 1.6;
  font-weight: 500;
}
#main.single .date {
  color: #707070;
  font-size: 12px;
}
#main.single .post-body {
  padding: 15px 0px 60px;
}
#main.single .prev-next {
  width: 40%;
  margin: 0 auto 60px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
}
#main.single .prev-next a {
  display: block;
  padding: 16px 20px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#main.single .prev-next a:hover {
  background-color: #e95959;
  color: #fff;
}
#main.single .post-body p {
  line-height: 2;
  margin: revert;
}

.pager .pagination {
  margin-bottom: 60px;
  text-align: center;
}

.pager .pagination li {
  display: inline;
  margin: 0 2px;
  padding: 0;
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
  position: relative;
  border-radius: 10px;
}

.pager .pagination li a {
  vertical-align: middle;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: table;
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.pager .pagination li a span {
  display: table-cell;
  vertical-align: middle;
}

.pager .pagination li a:hover,
.pager .pagination li a.active {
  color: #fff;
  background: #e95959;
}

@media only screen and (max-width: 1120px) {
  .pager .pagination li {
    display: none;
  }
  .pager .pagination li.pre,
  .pager .pagination li.next {
    display: inline-block;
    width: 40%;
    height: 50px;
    text-align: center;
  }
  .pager .pagination li.pre a,
  .pager .pagination li.next a {
    width: 100%;
    text-align: center;
  }
  .pager .pagination li.pre span::after {
    content: "　前の10件へ";
  }
  .pager .pagination li.next span::before {
    content: "次の10件へ　";
  }
}
#sidebar aside {
  border: 1px solid #d0d0d0;
  margin-bottom: 20px;
  border-radius: 10px;
}
#sidebar aside h4 {
  padding: 15px;
  border-bottom: 1px solid #d0d0d0;
  text-align: center;
  color: #e95959;
}
#sidebar aside li {
  padding: 15px;
  border-bottom: 1px solid #d0d0d0;
}
#sidebar aside li:last-child {
  border-bottom: none;
}
#sidebar aside a {
  display: block;
  font-size: 14px;
  line-height: 24px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
#sidebar aside a:hover {
  color: #e95959;
}

@media screen and (max-width: 1120px) {
  header {
    min-height: 60px;
    max-height: 60px;
  }
  header #h-left h1 a {
    display: block;
    padding-left: 10px;
  }
  header #h-left h1 img {
    width: 110px;
    height: auto;
  }
  header #h-left p {
    display: none;
  }
  header #h-right {
    min-height: 60px;
    max-height: 60px;
  }
  header #h-mail a {
    min-height: 60px;
    max-height: 60px;
    font-size: 10px;
    background-position: center 13px;
  }
  header #h-tel {
    display: none;
  }
  header #h-blog {
    border-right: none;
  }
  #mv img {
    width: 95%;
    margin-top: -31px;
  }
  .top-h2ttl {
    padding-top: 70px;
    font-size: 30px;
    line-height: 40px;
    background-size: 60px;
  }
  .top-h2ttl__ub {
    padding-bottom: 11px;
  }
  .top-h2ttl__ub::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: calc(50% - 22px);
    display: inline-block;
    background-image: url(../img/ttl_ul.svg);
    background-repeat: no-repeat;
    width: 44px;
    height: 2.5px;
  }
  .top-lead {
    width: 90%;
    margin: 19px auto 0 auto;
  }
  #feature {
    padding-top: 40px;
    padding-bottom: 60px;
  }
  #feature .flex-wrapper {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  #feature .flex-wrapper dl {
    width: 90%;
    max-width: 90%;
  }
  #case {
    padding-top: 50px;
  }
  #case h3 {
    max-width: 90%;
    font-size: 26px;
  }
  #case #case-list > li {
    padding-left: 5%;
  }
  #case #case-list dl {
    padding-left: 0;
    max-width: 90%;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  #case #case-list dt {
    text-align: center;
    margin-bottom: 20px;
  }
  #case #case-list dt,
  #case #case-list dd {
    width: 100%;
  }
  #case #top-logoarea {
    margin: 0 auto;
  }
  #case #top-logoarea ul {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 50px;
  }
  #case #top-logoarea li {
    width: 15%;
    margin: 0 5% 28px 5%;
  }
  #case #top-logoarea li img {
    max-width: 100px;
    width: 100%;
    height: auto;
  }
  #case #top-logoarea .balloon {
    min-width: 90vw;
    bottom: 120%;
  }
  #case #top-logoarea .balloon2,
  #case #top-logoarea .balloon6 {
    left: -167%;
  }
  #case #top-logoarea .balloon3,
  #case #top-logoarea .balloon7 {
    left: -333%;
  }
  #case #top-logoarea .balloon4,
  #case #top-logoarea .balloon8 {
    left: revert;
    right: 0;
  }
  #case #top-logoarea .balloon1:before,
  #case #top-logoarea .balloon5:before {
    left: 8%;
  }
  #case #top-logoarea .balloon2:before,
  #case #top-logoarea .balloon6:before {
    left: 36.5%;
  }
  #case #top-logoarea .balloon3:before,
  #case #top-logoarea .balloon7:before {
    left: 63.5%;
  }
  #case #top-logoarea .balloon4:before,
  #case #top-logoarea .balloon8:before {
    left: 92%;
  }
  #plan {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  #plan #top-cpn {
    margin-top: 21px;
    margin-bottom: 24px;
  }
  #flow {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  #flow #step {
    margin-top: 43px;
  }
  #flow sub {
    margin-top: 20px;
  }
  #contact dl {
    max-width: 90%;
  }
  #footer-in {
    display: block;
    width: 90%;
    margin: 0 auto;
    padding-top: 15px;
  }
  #footer-in .logo {
    width: 110px;
    padding-right: 0;
    margin-bottom: 15px;
  }
  #footer-in .logo img {
    width: 100%;
    height: auto;
  }
  #footer-in #footer-about {
    padding-left: 0;
    border-left: none;
  }
  #wrapper {
    padding-top: 30px;
    padding-bottom: 30px;
    display: block;
  }
  #main {
    width: 90%;
    margin: 0 auto;
  }
  #arc-list {
    display: block;
    padding-bottom: 40px;
  }
  #arc-list article {
    width: 100%;
  }
  #arc-list h3 {
    min-height: auto;
  }
  #sidebar {
    width: 90%;
    margin: 0 auto;
  }
}